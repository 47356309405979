<template>
<div class="text-white ">
  


<!-- -->
  <div class="md:max-w-screen-xl p-10 md:p-40">
    <h1 class="text-5xl text-left py-5">Hallo!</h1>
    <div class="text-2xl md:text-3xl  text-justify md:leading-relaxed">
      Schön, dass Du hierher gefunden hast. Hast du selbst ein Haustier, dass Du liebst oder kennst jemanden, für den Du noch ein tolles, persönliches Geschenk brauchst? Dann schau Dich doch gerne einmal um! Hier findest Du unter anderem eine Galerie mit Bildern, die ich gezeichnet habe - dann weißt Du, was Dich erwartet. 
    </div>
  </div>



</div>
</template>

<script>

export default {
  name: 'Landing',
  props: {
    
  },
  components:{

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
