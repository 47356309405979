<template>
    <div>
      <!-- Desktop Menu --> 
      <hide-at breakpoint="mediumAndBelow">
        <div class="bg-primary overflow-hidden sticky filter drop-shadow-2xl">
          <div class="fitin bg-secondary"></div>
          <div class="grid grid-cols-5  fronted text-secondary"> 
            <router-link class="self-center text-4xl px-20"  to="/"> <img class="max-h-24 " src="../assets/logo.png"></router-link>
            <div class="group col-start-2  self-center transform hover:scale-110 ">
              <router-link class="self-center text-4xl"  to="/about">About</router-link>
            </div>
            <div class="self-center transform hover:scale-110 ">
              <router-link class="self-center text-4xl"  to="/galery">Galerie</router-link>
            </div>
            <div class="self-center transform hover:scale-110">
              <router-link class="self-center text-4xl"  to="/work">Aufträge</router-link>
            </div>
            <div class="self-center text-left"> <!-- Socials TODO -->
              <div class="transform hover:scale-110">
                 <button onclick="location.href='https://www.instagram.com/artbymip/'">
                @artbyMIP
              </button> 
              </div>
              <div class="transform hover:scale-110 ">
                <button class="" v-on:click="onClickButton">
                  Contact@artbymip.de
                </button>
              </div>
            </div>
          </div>
        </div>
      </hide-at>
      <!-- Mobile Menu -->
      <show-at breakpoint="mediumAndBelow">
        <div class="bg-secondary hideme sticky filter drop-shadow-2xl">
        
          <div class="flex justify-between mx-auto">
            <div class="z-10">
              <button v-on:click="nav = !nav" class="mx-10">
                <span class="mx-10 py-10 self-center justify-self-center">
                <!--<svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 fill-current text-primary " viewBox="0 0 50 50" stroke="currentColor">

                </svg>-->
                <img src="../assets/svg/palett.svg" />
                </span>
              </button>
            </div>
            <div class="justify-end self-center overflow-hidden z-10">
              <img src="../assets/logo.png" class="max-h-24">
            </div>
          </div>
        </div>
      </show-at>
      <!-- mobile menu -->
      <div class="grid grid-col-1 fixed bg-primary text-secondary min-w-full py-10 sticky z-20" v-bind:class="{ hidden: !nav }">
          <button class="flex self-center min-w-full" v-on:click="nav = !nav">
              <router-link class="self-center text-left text-3xl mx-20"  to="/">Home</router-link>
          </button>
          <hr class="mx-10">
          <button class="flex self-center min-w-full" v-on:click="nav = !nav">
            <router-link class="self-center text-left text-3xl mx-20"  to="/about">About</router-link>
          </button>
          <hr class="mx-10">
          <button class="flex self-center min-w-full" v-on:click="nav = !nav">
            <router-link class="self-center text-left text-3xl mx-20"  to="/galery">Galerie</router-link>
          </button>
          <hr class="mx-10">
          <button class="flex self-center min-w-full" v-on:click="nav = !nav">
            <router-link class="self-center text-left text-3xl mx-20"  to="/work">Aufträge</router-link>
          </button>
          <hr class="mx-10">
          <button class="flex self-center min-w-full" v-on:click="nav = !nav">
            <router-link class="self-center text-left text-3xl mx-20"  to="/imprint">Impressum /<br> Datenschutz</router-link>
          </button>
          <button onclick="location.href='mailto:contact@artbymip.de'" class="bg-secondary text-primary self-end">
                  Contact@artbymip.de
          </button>
        
      </div>
    </div>
</template>

<script>
import {hideAt, showAt} from 'vue-breakpoints'


export default {
  components: {
    hideAt, showAt
   },
  name: 'nav-bar',
  props: [ 'formactive' ],
  data() {
    return {
     nav : false,
    };
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
     onClickButton () {
      this.$emit('clicked', 'someValue')
    },

    myEventHandler() {
      this.nav = false
    }
  }
}
</script>

<style scoped>
.fitin {
      left:-20%;
      transform: skew(-30deg);
      -webkit-transform:  skew(-30deg);
      height: 400%;
      width: 30%;
      z-index: -1;
      position: fixed;
    }
  .fronted{
     position: relative;
     z-index: 100;

  }

.positioned{
  z-index: 2;

}

.hideme{
  overflow: hidden;

}

.NavbarDecor {
      right:-10%;
      top:0%;
      transform: skewX(30deg);
       -webkit-transform: skewX(30deg);
      -moz-transform: skewX(30deg);
      height: 100%;
      width: 60%;
      z-index: -1;
      position: fixed;
    }




</style>