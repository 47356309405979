<template>
  <div id="app" class="">

    <!-- Contact Form
    
    <button v-on:click="formactive = false" class="place-self-end dot bg-secondary h-10 w-10">
      <div class="innerdot bg-primary p-1">
        <div class="rectangle bg-secondary transform -rotate-45 ">
          <div class="rectangle bg-secondary transform rotate-90 ">
          </div>
        </div>
      </div>
    </button>
    
    <button onclick="location.href='mailto:lennart.becker@outlook.com'" class="bg-primary p-5 rounded text-secondary">
        Zu Mailprogramm wechseln
    </button> 

     -->

    <div v-if="formactive"  class="flex formcontainer place-content-center" >
      <div class="form-bg absolute z-0">
        
      </div>
      <div class="flex lg:px-96 lg:py-40 place-content-center form-content z-10">
        <div class="bg-primary contactform rounded overflow-hidden">
          <div class="z-0">
            <div class="FormDecor">
            </div>
            <img class="top-0 left-0 z-20 h-20 p-2 relative fixed" src="./assets/logo.png">
          </div>
          <div class="grid lg:p-5">
              <div class="place-self-end">
              <button v-on:click="formactive = false" class=" dot bg-secondary h-10 w-10">
                <div class="innerdot bg-primary p-1">
                  <div class="rectangle bg-secondary transform -rotate-45">
                    <div class="rectangle bg-secondary transform rotate-90">
                    </div>
                  </div>
                </div>
              </button>
              </div>
              <div>
                <p class="text-secondary text-xl">Dieses Feature ist leider noch nicht verfügbar. <br> Nutzen Sie stattdessen Bitte den Folgenden Button: <br><br> </p>
              </div>
              <div class="row-span-2">
              </div>
              <div>
              <button onclick="location.href='mailto:contact@artbymip.de'" class="bg-secondary p-5 rounded text-primary self-end">
                <h1 class="text-4xl">Zu Mailprogramm wechseln</h1>
              </button>
              </div> 
          </div>
        </div>
      </div>
      
    </div>

    <!-- End of Contact From --> 
    
    <nav id="nav" class="m-auto sticky top-0 z-20">
      
      <NavigationBar @clicked="setFormActive"/>
    
    </nav>
    
    

    <div v-bind:class="selectBackground" class="m-auto z-10 relative min-h-screen bg-no-repeat bg-cover bg-fixed">
      <router-view/>
    </div>

    <hide-at breakpoint="mediumAndBelow">
      <footer class="bg-primary z-0 bottom-0 sticky overflow-hidden p-8">
        <div class="FooterDecor">
        </div>
        <div class="grid grid-rows-1 grid-cols-2">
          <div class="text-secondary grid grid-cols-2">
            <div class="grid grid-cols-1">
              <div class="px-10">
                <router-link class="self-center"  to="/about">About</router-link>
                <hr class="links">
              </div>
              <div class="px-10">
                <router-link class="self-center"  to="/galery">Galerie</router-link>
                <hr class="links">
              </div>
              <router-link class="self-center"  to="/work">Aufträge</router-link>
            </div>
            <div class="grid grid-cols-1 grid-rows-3">
              <div class="rwo-span-1">  

              </div>
              <div class="px-10">
                <router-link class="self-center"  to="/imprint">Datenschutzerklärung</router-link>
                <hr class="links">
              </div>
              <div class="px-10">
                <router-link class="self-center"  to="/imprint">Impressum</router-link>
              </div>
            </div>
          </div>
          <div class="text-primary place-self-end px-20">
            <ul>
            <li>Kontakt</li>
            <li><hr  class="contacts"></li>
            <li>contact@artbymip.de</li>
            <li>+49 176 42123655</li>
            </ul>
          </div>
        </div>
      </footer>
    </hide-at>
    
  </div>

</template>


<script>
import NavigationBar from './components/navigationbar.vue'
import {hideAt} from 'vue-breakpoints'

export default {
  name: 'App',
  components: {
    NavigationBar,
    hideAt 
  }, 
  data: function () {
    return {
      formactive : false
    }
  },
  methods:{
    setFormActive: function(){
      if(this.formactive != true){
      console.log("is set active")
      this.formactive = true;
      }else{
        console.log("is set inactive")
        this.formactive = false;
      }
    }
  },
  computed:{
    selectBackground() {
        switch (this.$route.name){
          case 'About':
            return "bg-about"
          case 'Work':
            return ''
          case 'Galery':
            return 'bg-secondary'
          case 'Imprint':
            return 'bg-secondary'
           default:
            return"bg-landing"
        }
    },
  }
  
}
</script>

<style>

#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.borderline{
   border-top: 3px solid #404040;
}

.contacts{
   border-top: 1px solid #663838;
}

.links{
  border-top: 1px solid #E8D6cb;
}



.FooterDecor {
    top:0px;
      right: 0px;
      width: 20rem;
      position: absolute;
      height: 0;
      border-left: 600px solid transparent;
      border-right: 0px solid transparent;
      border-top: 400px solid #E8D6cb;
      z-index: -100;
    }

.FormDecor {
      width: 5%;
      position: fixed;
      height: 0;
      border-left: 0px solid transparent;
      border-right: 200px solid transparent;
      border-top: 200px solid #E8D6cb;
      z-index: 0;
    }



.insetShadow{
  box-shadow: 0px 0px 20px #000 inset ;
}

.contactform{
  width: 60%;
  height : 100%;
}

.formcontainer{
  width:100%;
  height : 100%;
  z-index: 100;
  position: fixed;
}

.form-bg{
  width:100%;
  height : 100%;
  background: #0000008c;
}

.exitbtn{
  position: relative;
}

.dot {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  

}

.innerdot {
  display: flex;
  justify-content: center;
  align-items: center;
  height:80%;
  width: 80%;
  border-radius: 50%;
}

.form-content{
  height: 100%;
  width: 100%;
}

.rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5px;
  border-radius: 25%;
  width: 100%;
}


</style>
